import classNames from "classnames/bind"

import styles from './CreatorVideos.module.scss'

const cx = classNames.bind(styles)

function CreatorVideos() {
    return (
        <div className={cx('wrapper')}>
            CreatorVideos
        </div>
    )
}

export default CreatorVideos;